import BigNumber from "bignumber.js";

type FormatForceOptions = {
  minValue: number;
};

type LocalizeOptions = {
  min: number;
  max: number;
};

const localize = (value: number, options: LocalizeOptions): string => {
  const { min, max } = options;

  const localizedValue = value.toLocaleString("ru-RU", {
    maximumFractionDigits: max,
    minimumFractionDigits: min,
  });

  return localizedValue.replace(",", ".");
};

export const formatNumber = (
  input: number | string,
  digitsAfterDots?: number,
  forceOptions?: FormatForceOptions
): string => {
  if (!input) return "";

  const max = digitsAfterDots || 3;
  let value: number;

  if (typeof input === "string") {
    const bigNum = new BigNumber(input).toFixed();
    value = parseFloat(bigNum);
  } else {
    value = input;
  }

  if (value < 0.001) {
    const result = localize(value, { min: 0, max: 20 });
    const allowedMinValue = forceOptions
      ? Math.max(value, forceOptions.minValue)
      : result;

    return String(allowedMinValue || 0);
  }

  return localize(value, { min: 0, max });
};
