import { ReplyFormData } from "@common/type/type";
import Joi from "joi";

export const ReplyValidationSchema = Joi.object<ReplyFormData>({
  user_email: Joi.string().min(5).email({ tlds: false }).required().messages({
    "string.empty": "error_email",
    "string.email": "error_email",
    "string.min": "error_email",
  }),
  comment: Joi.string().min(5).required().messages({
    "string.empty": "error_message",
    "string.min": "error_message",
  }),
  review_id: Joi.string(),
});
