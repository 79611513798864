import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { BASE_URL, LANGUAGE_LIST } from "@common/constant/constant";
import { MetaData } from "@common/type/type";
import { AppLanguage } from "@common/enum/enum";

function Meta({ title, noIndexing = false, description }: MetaData) {
  const { locale, asPath } = useRouter();
  const canonicalLocale = locale === AppLanguage.EN ? "" : `/${locale}`;

  return (
    <Head>
      <title>{title}</title>
      {noIndexing ? (
        <meta name="robots" content="noindex, nofollow" />
      ) : (
        <>
          <meta name="description" content={description} />
          <link
            rel="canonical"
            href={`${BASE_URL}${canonicalLocale}${asPath}`}
          />
          {LANGUAGE_LIST.map(lang => {
            const hrefLocale = lang === AppLanguage.EN ? "" : `/${lang}`;

            return (
              <link
                key={lang}
                rel="alternate"
                hrefLang={lang === AppLanguage.UA ? "uk" : lang}
                href={`${BASE_URL}${hrefLocale}${asPath}`}
              />
            );
          })}
          <meta property="og:url" content={BASE_URL} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta
            property="og:image"
            content="https://www.example.com/og-image.jpg"
          />
          <meta property="og:site_name" content="Market exchangers" />
        </>
      )}
    </Head>
  );
}

export default Meta;
