import { PropsWithChildren, useId } from "react";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";
import { useTranslation } from "next-i18next";
import Typography from "@components/common/Typography";
import styles from "./CommentCheckbox.module.scss";

type ExtraProps<T extends FieldValues> = {
  name: keyof T;
  errorMessage?: string;
  register: UseFormRegister<T>;
};
type Props<T extends FieldValues> =
  React.InputHTMLAttributes<HTMLInputElement> & ExtraProps<T>;

export default function Checkbox<T extends FieldValues>({
  name,
  children,
  errorMessage,
  register,
  ...props
}: Props<T> & PropsWithChildren) {
  const id = useId();
  const { t } = useTranslation("form");

  const registerValue = register(name as Path<T>);

  return (
    <label className={styles.label} style={{ gridArea: name }} htmlFor={id}>
      <input
        className={styles.input}
        type="checkbox"
        {...registerValue}
        {...props}
        id={id}
      />
      <div className={styles.checkbox} />
      {children}
      {errorMessage && (
        <Typography as="p" addClass={styles.error}>
          {t(errorMessage)}
        </Typography>
      )}
    </label>
  );
}
