import { PartnersFormData } from "@common/type/type";
import Joi from "joi";

export const PartnersValidationSchema = Joi.object<PartnersFormData>({
  name: Joi.string().min(2).required().messages({
    "string.empty": "error_name",
    "string.min": "error_name",
  }),
  email: Joi.string().min(5).email({ tlds: false }).required().messages({
    "string.empty": "error_email",
    "string.email": "error_email",
    "string.min": "error_email",
  }),
  xml_url: Joi.string().uri().required().messages({
    "string.empty": "error_xml_link",
    "string.min": "error_xml_link",
    "string.uri": "error_invalid_uri",
  }),
  project_url: Joi.string().uri().required().messages({
    "string.empty": "error_project_url",
    "string.min": "error_project_url",
    "string.uri": "error_invalid_uri",
  }),
  comment: Joi.string().min(5).required().messages({
    "string.empty": "error_message",
    "string.min": "error_message",
  }),
  terms: Joi.any().valid(true).invalid(false).required().messages({
    "any.only": "error_checkbox",
  }),
  policy: Joi.any().valid(true).invalid(false).required().messages({
    "any.only": "error_checkbox",
  }),
});
