import React, { useId } from "react";
import { useTranslation } from "next-i18next";
import classNames from "classnames";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";
import ErrorIcon from "@public/svg/icons/error.svg";
import Typography from "@components/common/Typography";
import styles from "./Input.module.scss";

type ExtraProps<T extends FieldValues> = {
  name: keyof T;
  errorMessage?: string;
  register?: UseFormRegister<T>;
};
type Props<T extends FieldValues> =
  React.InputHTMLAttributes<HTMLInputElement> & ExtraProps<T>;

export default function Input<T extends FieldValues>({
  name,
  errorMessage,
  register,
  ...props
}: Props<T>) {
  const { t } = useTranslation("form");
  const id = useId();

  const registerValue = register ? register(name as Path<T>) : {};

  return (
    <label className={styles.label} style={{ gridArea: name }} htmlFor={id}>
      <input
        id={id}
        {...props}
        {...registerValue}
        className={classNames(errorMessage && styles.invalid, styles.input)}
      />
      {errorMessage && (
        <>
          <Typography as="p" addClass={styles.error}>
            {t(errorMessage)}
          </Typography>
          <ErrorIcon className={styles.error_icon} />
        </>
      )}
    </label>
  );
}
