import React from "react";
import styles from "./Loader.module.scss";

export default function CommonLoader() {
  return (
    <svg
      className={styles.loader}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="12.3077"
        y="12.3077"
        width="15.3846"
        height="15.3846"
        rx="3"
        fill="#C3F533"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.6316C0 5.65535 5.51034 0 12.3077 0H13.8462V8.42105H12.3077C10.0419 8.42105 8.20513 10.3062 8.20513 12.6316V27.3684C8.20513 29.6938 10.0419 31.5789 12.3077 31.5789H18.4615V40H12.3077C5.51034 40 0 34.3447 0 27.3684V12.6316Z"
        fill="url(#paint0_linear_2554_17933)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 27.3684C40 34.3446 34.4897 40 27.6923 40L26.1538 40L26.1538 31.5789L27.6923 31.5789C29.9581 31.5789 31.7949 29.6938 31.7949 27.3684L31.7949 12.6316C31.7949 10.3062 29.9581 8.42105 27.6923 8.42105L21.5385 8.42105L21.5385 2.20074e-06L27.6923 2.73872e-06C34.4897 3.33297e-06 40 5.65535 40 12.6316L40 27.3684Z"
        fill="url(#paint1_linear_2554_17933)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2554_17933"
          x1="5.1282"
          y1="30.5263"
          x2="18.5491"
          y2="35.5558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3F533" />
          <stop offset="1" stopColor="#C3F533" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2554_17933"
          x1="34.8718"
          y1="9.47369"
          x2="21.4509"
          y2="4.44416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3F533" />
          <stop offset="1" stopColor="#C3F533" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
