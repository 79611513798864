import React, { FormEvent, useEffect, useId, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";
import classNames from "classnames";
import Typography from "@components/common/Typography";
import ErrorIcon from "@public/svg/icons/error.svg";
import styles from "./Textarea.module.scss";

type ExtraProps<T extends FieldValues> = {
  name: keyof T;
  errorMessage?: string;
  register: UseFormRegister<T>;
};
type Props<T extends FieldValues> =
  React.InputHTMLAttributes<HTMLTextAreaElement> & ExtraProps<T>;

export default function Textarea<T extends FieldValues>({
  name,
  errorMessage,
  register,
  ...props
}: Props<T>) {
  const id = useId();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [value, setValue] = useState("");
  const { t } = useTranslation("form");

  const { ref, ...rest } = register(name as Path<T>);

  useEffect(() => {
    const textArea = textareaRef.current;

    if (textArea) {
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }, [textareaRef, value]);

  const setupRefCallback = (elem: HTMLTextAreaElement | null) => {
    ref(elem);
    textareaRef.current = elem;
  };

  const handleInput = (e: FormEvent<HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value);
  };

  return (
    <label className={styles.label} style={{ gridArea: name }} htmlFor={id}>
      <textarea
        ref={setupRefCallback}
        id={id}
        className={classNames(errorMessage && styles.invalid, styles.textarea)}
        onInput={handleInput}
        {...props}
        {...rest}
      />
      {errorMessage && (
        <>
          <Typography as="p" addClass={styles.error}>
            {t(errorMessage)}
          </Typography>
          <ErrorIcon className={styles.error_icon} />
        </>
      )}
    </label>
  );
}
