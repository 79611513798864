import { Control, Controller } from "react-hook-form";
import { Rating as RSSRating } from "react-simple-star-rating";
import { CommentFormData } from "@common/type/type";
import { AppColor } from "@common/enum/enum";
import { useTranslation } from "next-i18next";
import styles from "./Rating.module.scss";

type Props = {
  control: Control<CommentFormData>;
};

export default function Rating({ control }: Props) {
  const errorMessage = control.getFieldState("rating").error?.message;
  const { t } = useTranslation("form");

  return (
    <div className={styles.wrapper}>
      <Controller
        control={control}
        name="rating"
        render={({ field: { onChange, value } }) => (
          <RSSRating
            onClick={onChange}
            size={20}
            transition
            allowFraction
            SVGstrokeColor={errorMessage ? AppColor.RED : AppColor.NEW_PRIMARY}
            SVGstorkeWidth={1}
            fillColor={AppColor.NEW_PRIMARY}
            emptyColor={AppColor.COLOR_DARK}
            initialValue={value || 0}
            style={{ gridArea: "rating" }}
          />
        )}
      />
      {errorMessage && <div className={styles.error}>{t(errorMessage)}</div>}
    </div>
  );
}
