import Loader from "@components/ui/Loader/CommonLoader";
import styles from "./InitialLoader.module.scss";

type Props = {
  height?: number;
};

export default function InitialLoader({ height = 320 }: Props) {
  return (
    <div style={{ height }} className={styles.wrapper}>
      <Loader />
    </div>
  );
}
