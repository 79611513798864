import { FeedbackFormData } from "@common/type/type";
import Joi from "joi";

export const FeedbackValidationSchema = Joi.object<FeedbackFormData>({
  name: Joi.string().min(2).required().messages({
    "string.empty": "error_name",
    "string.min": "error_name",
  }),
  email: Joi.string().min(5).email({ tlds: false }).required().messages({
    "string.empty": "error_email",
    "string.email": "error_email",
    "string.min": "error_email",
  }),
  comment: Joi.string().min(5).required().messages({
    "string.empty": "error_message",
    "string.min": "error_message",
  }),
  terms: Joi.any().valid(true).invalid(false).required().messages({
    "any.only": "error_checkbox",
  }),
  policy: Joi.any().valid(true).invalid(false).required().messages({
    "any.only": "error_checkbox",
  }),
});
